<template>
	<div class="personalmanage">
		<h1 class="page_title">{{ $route.meta.title }}</h1>
		<a-form
			:form="form"
			:label-col="{ span: 10 }"
			:wrapper-col="{ span: 8 }"
		>
			<a-form-item label="用户名称"> {{name}} </a-form-item>
			<a-form-item label="手机号">
				<a-input
					:disabled="!is_edit"
					autocomplete="off"
					placeholder="请输入手机号"
					v-decorator="[
						'phone',
						{
							rules: [
								{
									required: true,
									message: '请输入正确的手机号',
									pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/
								}
							],
							initialValue: ''
						}
					]"
				/>
			</a-form-item>
			<a-form-item label="邮箱">
				<a-input
					:disabled="!is_edit"
					autocomplete="off"
					placeholder="请输入邮箱"
					v-decorator="[
						'email',
						{
							rules: [
								{
									required: true,
									message: '请输入正确的邮箱',
									pattern:  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
								}
							],
							initialValue: ''
						}
					]"
				/>
			</a-form-item>
		</a-form>
		<a-row>
			<a-col :offset="10">
				<a-button
					type="primary"
					style="margin-right: 16px"
					@click="editInfo"
				>
					{{ is_edit ? "保存" : "修改信息" }}
				</a-button>
				<a-button type="primary" @click="editPwd">修改密码</a-button>
			</a-col>
		</a-row>
		<a-modal
			title="密码修改"
			:visible="visible"
			@cancel="cancelModal"
			@ok="okModal"
		>
			<a-form
				:form="formModal"
				:label-col="{ span: 8 }"
				:wrapper-col="{ span: 12 }"
			>
				<a-form-item label="原密码">
					<a-input
						autocomplete="off"
						placeholder="请输入密码"
						v-decorator="[
							'oldPwd',
							{
								rules: [
									{
										required: true,
										message: '请输入密码'
									}
								]
							}
						]"
					/>
				</a-form-item>
				<a-form-item label="新密码">
					<a-input
						autocomplete="off"
						placeholder="8-20位大小写字母和数字组合"
						v-decorator="[
							'newPwd',
							{
								rules: [
									{
										required: true,
										message: '8-20位大小写字母和数字组合',
										validator: validatornewPwd
									}
								]
							}
						]"
					/>
				</a-form-item>
				<a-form-item label="确认新密码">
					<a-input
						autocomplete="off"
						placeholder="8-20位大小写字母和数字组合"
						v-decorator="[
							'againPwd',
							{
								rules: [
									{
										required: true,
										validator: validatorAgainPwd
									}
								]
							}
						]"
					/>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import { userInfo, updateBaseInfo, changePassword } from "@/api/index";
		
	export default {
		name: "PersonCenter",
		data() {
			return {
				name: "",
				form: this.$form.createForm(this),
				is_edit: false,
				visible: false,
				formModal: this.$form.createForm(this)
			};
		},
		mounted() {
			this.userInfo();
		},
		methods: {
			userInfo() {
				let data = {};
				userInfo(data).then(res => {
					this.name = res.data.name;
					this.form.setFieldsValue({
						phone: res.data.phone,
						email: res.data.email
					});
				});
			},

			editInfo() {
				if (this.is_edit) {
					this.form.validateFields((error, values) => {
						if (!error) {
							let data = {
								email: values.email,
								phone: values.phone
							};
							updateBaseInfo(data)
								.then(res => {
									if (res.code == 200) {
										if(res.data){
											this.$message.success("修改信息成功");
										}else{
											this.$message.error("修改信息失败");
										}
									} else {
										this.$message.error(res.msg);
									}
								})
								.catch();
							
							this.is_edit = !this.is_edit;
						}
					});
				}else{
					this.is_edit = !this.is_edit;
				}
				
			},
			editPwd() {
				this.visible = true;
			},
			cancelModal() {
				this.formModal.resetFields();
				this.visible = false;
			},
			okModal() {
				this.formModal.validateFields((error, values) => {
					if (!error) {
						let data = {
							originalPassword: values.oldPwd,
							password: values.newPwd
						};
						changePassword(data)
							.then(res => {
								if (res.code == 200) {
									if(res.data){
										this.$message.success("密码修改成功");
										this.$router.push({ name: "login" });
									}else{
										this.$message.error("密码修改失败");
									}
								} else {
									this.$message.error(res.msg);
								}
							})
							.catch();
					}
				});
			},
			// 校验密码
			validatornewPwd(rule, value, callback) {
				let reg = /(?=\S*\d)(?=\S*[a-z])(?=\S*[A-Z])^[\S]{8,20}$/;
				if (reg.test(value)) {
					callback();
				} else {
					callback("8-20位大小写字母和数字组合");
				}
				this.formModal.validateFields(["againPwd"]);
			},
			validatorAgainPwd(rule, value, callback) {
				let reg = /(?=\S*\d)(?=\S*[a-z])(?=\S*[A-Z])^[\S]{8,20}$/;
				let newPwd = this.formModal.getFieldValue("newPwd");
				if (value !== newPwd) {
					callback("密码不一致");
				} else {
					if (reg.test(value)) {
						callback();
					} else {
						callback("8-20位大小写字母和数字组合");
					}
				}
			}
		}
	};
</script>

<style lang="scss">
	.personalmanage{
		form{
			margin-top: 100px;
		}
	}
	
</style>
